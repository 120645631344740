/* eslint-disable import/prefer-default-export */
import { mapValues } from 'lodash-es';
import { ContactEmailType, Fuel, FurnaceType, LayoutType, ProjectFilter, ServiceType } from './enums';

export const MILLISECONDS_PER_SECOND = 1000;

export const FEET_PER_METER = 3.2808;

export const EMPTY_JSON_OBJECT_AS_STRING = '{}';

// https://www.eia.gov/energyexplained/units-and-calculators/british-thermal-units.php

export const FUEL_BTU = {
  [Fuel.ELECTRIC_KWH]: 3412, // KWh
  [Fuel.NATURAL_GAS_THERM]: 100000, // Therm
  [Fuel.HEATING_OIL_GAL]: 138500, // Gallon
  [Fuel.PROPANE_GAL]: 91502, // Gallon
};

// https://www.epa.gov/sites/production/files/2015-07/documents/emission-factors_2014.pdf
export const GWP = {
  CO2: 1,
  METHANE: 25,
  NITROUS_OXIDE: 298,
};

const MMBTU = 1000 * 1000;

export const MONTHS_PER_YEAR = 12;
export const HOURS_PER_DAY = 24;
export const HOURS_PER_YEAR = HOURS_PER_DAY * 365;

const FUEL_UNIT_PER_MMBTU = mapValues(FUEL_BTU, (fuelBtu) => MMBTU / fuelBtu);

// Kg CO2e / mmBTU
// https://www.epa.gov/sites/production/files/2015-07/documents/emission-factors_2014.pdf
export const FUEL_CO2E_PER_MMBTU = {
  [Fuel.NATURAL_GAS_THERM]: 53.06 * GWP.CO2 + (1 / 1000) * GWP.METHANE + (0.1 / 1000) * GWP.NITROUS_OXIDE,
  [Fuel.HEATING_OIL_GAL]: 73.96 * GWP.CO2 + (3 / 1000) * GWP.METHANE + (0.6 / 1000) * GWP.NITROUS_OXIDE,
  [Fuel.PROPANE_GAL]: 62.87 * GWP.CO2 + (3 / 1000) * GWP.METHANE + (0.6 / 1000) * GWP.NITROUS_OXIDE,
};

export const FUEL_CO2E_PER_UNIT = mapValues(
  FUEL_CO2E_PER_MMBTU,
  (fuelValue, fuel) => fuelValue / (FUEL_UNIT_PER_MMBTU as { [key: string]: number })[fuel],
);

export const DEFAULT_CEILING_HEIGHT_IN_FT = 8;

export const INITIAL_MIN_TEMPERATURE_DAY = 70;
export const INITIAL_MIN_TEMPERATURE_NIGHT = 65;
export const INITIAL_MAX_TEMPERATURE_DAY = 80;
export const INITIAL_MAX_TEMPERATURE_NIGHT = 75;

export const INITIAL_EXISTING_SEER = 10;

export const MIN_VALID_YEAR_BUILT = 1800;
export const MIN_VALID_PANEL_SLOTS = 0;
export const MAX_VALID_PANEL_SLOTS = 50;
export const MAX_VALID_FURNACE_AGE = 100;
export const MIN_VALID_INDOOR_TEMP = 50;
export const MAX_VALID_INDOOR_TEMP = 100;
export const MIN_VALID_SQFT = 50;
export const MAX_VALID_SQFT = 10_000;
export const MIN_VALID_ROOM_SQFT = 20;
export const MAX_VALID_ROOM_SQFT = 3_000;
export const MIN_VALID_ADDRESS_LENGTH = 4;

export const MIN_EXISTING_SEER = 0;
export const MAX_EXISTING_SEER = 30;

export const HIGH_PRICE_DELTA = 4_000;
export const LOW_PRICE_DELTA = 1_000;

export const QUIET_NOISE_LEVEL = 65;

export const HOW_IT_WORKS_NAV_ITEM_LABEL = 'How It Works';

export const INSTANT_QUOTE_PATH = {
  WHOLE_HOME: 'Whole home',
  SINGLE_ROOM: 'Single Room',
  MULTI_ROOM: 'Specific Rooms',
  REPLACE_EXISTING: undefined, // no longer presented as an option in the UI
};

export const E_DEN_SHORT_NAME = 'EDEN';
export const E_DEN_LONG_NAME = 'E-Den Home Electrification, Inc.';

export const E_DEN_DOMAIN = 'e-denhomes.com';
export const BASE_E_DEN_URL = `https://www.${E_DEN_DOMAIN}`;

export const E_DEN_SUCCESS_PAGE = `${BASE_E_DEN_URL}/thank-you`;
export const E_DEN_PRIVACY_PAGE = `${BASE_E_DEN_URL}/privacy-policy`;
export const E_DEN_TERMS_AND_CONDITIONS_PAGE = `${BASE_E_DEN_URL}/terms-and-conditions`;
export const E_DEN_HOW_IT_WORKS_PAGE = `${BASE_E_DEN_URL}/how-it-works`;
export const E_DEN_HOW_DOES_IT_WORK_PAGE = `${BASE_E_DEN_URL}/resources/how-does-a-heat-pump-work`;
export const E_DEN_FAQ_PAGE = `${BASE_E_DEN_URL}/instant-quote-faqs-20220321`;
export const CONTACT_TEL = '8888178896';

export const NO_ERRORS = { generalErrors: [], fieldErrors: {}, serverFieldErrors: {} };

const wholeHomeFormMetadata = {
  showHeatingStep: true,
  heatingStepQuestionNum: 6,
};

export const FORM_METADATA = {
  [LayoutType.SINGLE_ROOM]: {
    showHeatingStep: false,
    heatingStepQuestionNum: -1,
  },
  [LayoutType.MULTIPLE_ROOMS]: {
    showHeatingStep: true,
    heatingStepQuestionNum: 5,
  },
  [LayoutType.WHOLE_HOME]: wholeHomeFormMetadata,
  [LayoutType.UNKNOWN]: wholeHomeFormMetadata,
  [LayoutType.REPLACE_EXISTING]: wholeHomeFormMetadata,
};

export const FORM_FIELDS_THAT_DEFAULT_TO_ZERO = [
  'upperSmall',
  'totalSmall',
  'upperMedium',
  'totalMedium',
  'upperLarge',
  'totalLarge',
  'upperXLarge',
  'totalXLarge',
];

export const QUARTER_TONS_PER_TON = 4;

export const SAVING_LIFESPAN = 15;

export const DEFAULT_HOUSEHOLD_SIZE = 2;

// These values should match those in public/styles/_sizes.scss
export const BREAKPOINT_WIDTHS = {
  EXTRA_EXTRA_LARGE: '1400px',
  EXTRA_LARGE: '1200px',
  LARGE: '992px',
  MEDIUM: '768px',
  SMALL: '576px',
  // extra small is < 576px
};

export const VALID_ADDRESS_COUNTRIES = ['us'];

export const NON_BREAKING_SPACE = '\u00A0';
export const NON_BREAKING_HYPHEN = '\u2011';

export const MIN_SAVINGS_TO_SHOW = 100;

export const MIN_PASSWORD_LENGTH = 10;

export const EDEN_PARTNER_SLUG = 'iq';
export const ADMIN_PATH = 'admin';

export const LAYOUT_TYPE_LABELS = {
  [LayoutType.REPLACE_EXISTING]: undefined, // no longer presented as an option in the UI
  [LayoutType.WHOLE_HOME]: 'Whole Home',
  [LayoutType.SINGLE_ROOM]: 'Single Room',
  [LayoutType.MULTIPLE_ROOMS]: 'Multiple Rooms',
  [LayoutType.UNKNOWN]: 'Unknown',
  [LayoutType.BOILER]: 'Boiler',
};

export const SERVICE_TYPE_LABELS = {
  [ServiceType.NEW]: 'Install',
  [ServiceType.REPAIR]: 'Repair',
  [ServiceType.REPLACE]: undefined, // no longer presented as an option in the UI
};

export const SERVICE_TYPE_CONTENT_OVERRIDE_KEYS = {
  NEW: ServiceType.NEW,
  REPAIR: ServiceType.REPAIR,
  REPLACE: undefined, // no longer presented as an option in the UI
} as const;

export const LAYOUT_TYPE_CONTENT_OVERRIDE_KEYS = {
  WHOLE_HOME: LayoutType.WHOLE_HOME,
  MULTIPLE_ROOMS: LayoutType.MULTIPLE_ROOMS,
  SINGLE_ROOM: LayoutType.SINGLE_ROOM,
  BOILER: LayoutType.BOILER,
} as const;

export const CONTACT_FORM_EMAIL_TYPES_FOR_LAYOUT_TYPES = {
  [LayoutType.BOILER]: ContactEmailType.BOILER,
  [LayoutType.WHOLE_HOME]: ContactEmailType.WHOLE_HOME,
  [LayoutType.MULTIPLE_ROOMS]: ContactEmailType.MULTIPLE_ROOMS,
  [LayoutType.SINGLE_ROOM]: ContactEmailType.SINGLE_ROOM,
  [LayoutType.UNKNOWN]: undefined,
  [LayoutType.REPLACE_EXISTING]: undefined,
};

export const FURNACE_TYPE_LABELS = {
  [FurnaceType.ELECTRIC_HEAT_PUMP]: 'Heat Pump',
  [FurnaceType.ELECTRIC_RESISTANCE]: 'Electric',
  [FurnaceType.NATURAL_GAS]: 'Natural Gas',
  [FurnaceType.OIL]: 'Oil',
  [FurnaceType.PROPANE]: 'Propane',
};

export const FURNACE_TYPE_CONTENT_OVERRIDE_KEYS = {
  ELECTRIC_RESISTANCE: FurnaceType.ELECTRIC_RESISTANCE,
  ELECTRIC_HEAT_PUMP: FurnaceType.ELECTRIC_HEAT_PUMP,
  OIL: FurnaceType.OIL,
  PROPANE: FurnaceType.PROPANE,
  NATURAL_GAS: FurnaceType.NATURAL_GAS,
} as const;

export const PROJECT_FILTER_LABELS = {
  [ProjectFilter.HEAT_AND_COOL]: 'Heating & A/C',
  [ProjectFilter.HEAT_ONLY]: 'Heating',
  [ProjectFilter.COOL_ONLY]: 'A/C',
};

export const PROJECT_FILTER_CONTENT_OVERRIDE_KEYS = {
  HEAT_AND_COOL: ProjectFilter.HEAT_AND_COOL,
  HEAT_ONLY: ProjectFilter.HEAT_ONLY,
  COOL_ONLY: ProjectFilter.COOL_ONLY,
} as const;

const HERO_PANEL_COLUMNS = 7;
export const HERO_PANEL_WIDTH = HERO_PANEL_COLUMNS / 12;

export const HSPF_TO_HSPF_2 = 0.85;

export const STEP_NAMES = {
  INTRO: 'Intro',
  INTRO_MOBILE: 'IntroMobile',
  LOCATION: 'Location',
  HOME: 'HomeData', // Path: /Building-Info
  REPLACE_EXISTING: 'ReplaceExisting', // Path: /Building-Info/Replace-Existing, stepHeader only
  ENERGY_INFO: 'EnergyInfo', // Path: /Energy-Info, values under HVACData should be merged into this
  SINGLE_ROOM: 'SingleRoom', // Path: /Room-Info/Single-Room
  MULTI_ROOM: 'MultiSplit', // Path: /Room-Info/Specific-Rooms
  HOUSEHOLD: 'Household', // Path: /Household
  RESULTS: 'Results',
} as const;

export const DEMO_COMPANY_NAME = 'Climate Experts';
export const DEMO_COMPANY_SLUG = 'climate-experts';
export const DEMO_COMPANY_DOMAIN = 'climateexperts.com';
export const DEMO_COMPANY_LICENSE = 'CCE-123*45';
export const DEMO_USER_NAME = 'Gene';
export const DEMO_USER_GIVEN_NAME = 'Gene';
export const DEMO_USER_FAMILY_NAME = 'Forest';

export const AUTH0_TOKEN_CLAIM_NAMESPACE = `https://api.${E_DEN_DOMAIN}/`;

export const GOOGLE_AUTH0_CONNECTION_NAME = 'Google-OAuth2-Authentication';
export const EMAIL_AUTH0_CONNECTION_NAME = 'Email-Password-Authentication';

export const OAUTH_REDIRECT_URL = process.env.NEXT_PUBLIC_OAUTH_REDIRECT_URL || 'http://localhost:3000/admin';
export const OAUTH_AUDIENCE = process.env.NEXT_PUBLIC_OAUTH_AUDIENCE || `https://api-dev.${E_DEN_DOMAIN}/`;

export const AUTH0_DOMAIN = process.env.NEXT_PUBLIC_AUTH0_DOMAIN || '';
export const AUTH0_CLIENT_ID = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID || '';

export const DEFAULT_INSTALLER_TIMEFRAME = '1-3 weeks';

export const IMAGE_FOLDER = `https://${process.env.NEXT_PUBLIC_IMAGE_SERVER}/images/`;
export const EDEN_LOGO = 'Eden_Logo_02_transparent.png';

export const REBATE_DISCLAIMER = 'Eligibility and availability are determined by the incentive provider.';
export const MAX_IRA_HOME_REBATE_AMOUNT = 8_000;
